<template>
	<div class="border-top pt-30 mt-30">
		<div class="grid grid-4 grid-gap-20">
			<div>
				<InputGroup
					v-model="form.productQuantity"
					label="Mongd"
					:small="true"
					:error="$v.form.productQuantity.$invalid && $v.form.productQuantity.$dirty"
					:errorMessage="'Vinaliga skriva skriva eina ella fleiri mongdir'"
					@blur="$v.form.productQuantity.$touch()"
				/>
			</div>

			<div>
				<label class="mb-5" for="product">Vøra</label>

				<VSelect :options="products" :small="true" :optionText="'name'" :optionValue="'name'" v-model="form.product" id="product"></VSelect>
			</div>

			<div>
				<InputGroup
					v-model="form.productDiscount"
					:label="`Avsláttur í % (${productCostString})`"
					:small="true"
					:error="$v.form.productDiscount.$invalid && $v.form.productDiscount.$dirty"
					:errorMessage="'Vinaliga skriva ein avsláttur millum 0 og 100'"
					@blur="$v.form.productDiscount.$touch()"
				/>
			</div>

			<InputGroup
				v-model="form.percentage"
				:label="percentageString"
				:small="true"
				:error="$v.form.percentage.$invalid && $v.form.percentage.$dirty"
				:errorMessage="'Vinaliga skriva % av umsetningi millum 0 og 100'"
				@blur="$v.form.percentage.$touch()"
			/>
		</div>

		<div class="grid grid-4 grid-gap-20">
			<InputGroup v-model="form.offset" label="Mótrokna fakturar" :small="true" />

			<InputGroup
				v-model="form.offsetAmount"
				label="Nøgd at mótrokna"
				:small="true"
				:error="$v.form.offsetAmount.$invalid && $v.form.offsetAmount.$dirty"
				:errorMessage="'Vinaliga skriva eitt tal'"
				@blur="$v.form.offsetAmount.$touch()"
			/>
		</div>

		<PdfOverlay
			:visible="pdfOverlay.visible"
			:data="pdfOverlay.data"
			:loading="pdfOverlay.loading"
			@send-invoice="sendInvoice"
			@close="
				pdfOverlay.visible = false;
				pdfOverlay.data = null;
			"
		/>

		<div class="text-right heavy mb-20" style="font-size: 18px">DKK {{ totalPurchaseAmount.format(2) }}</div>

		<div class="flex justify-end">
			<div class="btn small" :class="{ disabled: $v.form.$invalid || loading }" @click="openInvoiceOverlay()">
				Vís uppgerð <i class="ml-10 far fa-paper-plane"></i>
			</div>
		</div>
	</div>
</template>

<script>
import { required, between, minValue, numeric } from 'vuelidate/lib/validators';
import InputGroup from './InputGroup.vue';

import createTransactionPDF from '@/helpers/create-transaction-pdf';

import VSelect from '@/components/VSelect.vue';
import PdfOverlay from '@/components/PdfOverlay.vue';
import axios from 'axios';

export default {
	name: 'InvoiceLine',

	props: ['agreement', 'products'],

	components: {
		VSelect,
		InputGroup,
		PdfOverlay,
	},

	data() {
		return {
			loading: false,

			form: {
				product: null,
				productQuantity: 0,
				productDiscount: 0,
				percentage: 2.97,
				offset: '',
				offsetAmount: 0,
			},

			pdfOverlay: {
				visible: false,
				data: null,
				loading: false,
			},
		};
	},

	mounted() {
		this.form.productQuantity = this.getProductQuantity();
	},

	validations: {
		form: {
			product: {
				required,
			},

			productDiscount: {
				required,
				between: between(0, 100),
			},

			productQuantity: {
				required,
				minValue: minValue(1),
			},

			percentage: {
				required,
				between: between(0, 100),
			},

			offsetAmount: {
				required,
				minValue: minValue(0),
			},
		},
	},

	computed: {
		productCost() {
			if (!this.form.product) {
				return 0;
			}

			if (!this.form.productQuantity) {
				return 0;
			}

			const total = this.form.product.salesPrice * this.form.productQuantity;

			if (this.form.productDiscount && this.form.productDiscount > 0) {
				return total * ((100 - this.form.productDiscount) / 100);
			}

			return total;
		},

		totalPurchaseAmount() {
			const transactions = this.agreement[1];

			return this.getTotal(transactions);
		},

		productCostString() {
			return `DKK ${Number(this.productCost).format(2)}`;
		},

		percentageString() {
			return `% afturat sølu (DKK ${Number(this.percentageCost).format(2)})`;
		},

		percentageCost() {
			if (!this.form.percentage) {
				return 0;
			}

			return this.totalPurchaseAmount * (this.form.percentage / 100);
		},

		percentageCostString() {
			return `DKK ${Number(this.percentageCost).format(2)}`;
		},

		totalCost() {
			return this.productCost + this.percentageCost;
		},

		totalCostString() {
			return `DKK ${Number(this.totalCost).format(2)}`;
		},
	},

	watch: {
		agreement: {
			immediate: true,

			handler(value) {
				this.productQuantity = value.terminals?.length;
			},
		},

		products: {
			immediate: true,

			handler(value) {
				for (const product of value) {
					// Setting selected product to product with productNumber == 'TL' (Terminalleiga)
					if (product.productNumber == 'TL') {
						this.form.product = product;

						break;
					}
				}

				this.product = value[0];
			},
		},
	},

	methods: {
		getTotal(transactions) {
			let total = 0;

			for (const transaction of transactions) {
				total += +transaction.purchaseAmount;
			}

			return total;
		},

		getProductQuantity() {
			let terminals = [];

			for (const data of this.agreement[2]) {
				let terminal = data[0].split(' ')[1];

				terminals = [...terminals, terminal];
			}

			terminals = new Set(terminals);

			return [...terminals].length;
		},

		openInvoiceOverlay() {
			if (this.$v.form.$invalid) {
				return;
			}

			this.createPDFData();

			this.pdfOverlay.visible = true;
		},

		createPDFData(invoiceNumber = '') {
			const firstTransaction = this.agreement[1][0];

			// add the customer line
			const customer = [
				firstTransaction.customer_name,
				firstTransaction.customer_email,
				`${firstTransaction.bankAccount_registrationNumber ?? ''} ${firstTransaction.bankAccount_accountNumber ?? ''}`,
			];

			let transactions = [];

			let bankComment = '';

			if (firstTransaction.agreement_data && firstTransaction.agreement_data['bank-account-comment']) {
				bankComment = firstTransaction.agreement_data['bank-account-comment'];
			}

			let total = 0;

			// Add Data to transaction Line
			for (const data of this.agreement[2]) {
				let [date, terminal] = data[0].split(' ');

				const [d, m, y] = date.split('/');

				let sales = this.getTotal(data[1]);
				let percentageCost = (sales * this.form.percentage) / 100;

				date = new Date(y, Number(m) - 1, d);

				total += sales - percentageCost;

				transactions = [...transactions, [terminal, date, sales.format(2), `(${percentageCost.format(2)})`, '-', total.format(2)]];
			}

			// Sort the Transactions by Date
			transactions.sort((a, b) => {
				return a[1].getTime() - b[1].getTime();
			});

			// Format the Transaction Date
			transactions = transactions.map((transaction) => {
				return [transaction[0], transaction[1].format('%d/%m/%y'), transaction[2], transaction[3], transaction[4], transaction[5]];
			});

			total -= this.productCost * 1.25;

			transactions = [
				...transactions,
				[
					`Fakturi ${invoiceNumber ? invoiceNumber : ''}`,
					new Date().format('%d/%m/%y'),
					'-',
					'-',
					`(${(this.productCost * 1.25).format(2)})`,
					total.format(2),
				],
			];

			if (this.form.offset) {
				transactions = [
					...transactions,
					[this.form.offset, new Date().format('%d/%m/%y'), '-', '-', `(${Number(this.form.offsetAmount).format(2)})`, 0],
				];
			}

			transactions = [...transactions, ['Flutt', new Date().format('%d/%m/%y'), '-', '-', `(${(total - Number(this.form.offsetAmount)).format(2)})`, 0]];

			this.pdfOverlay.data = {
				customer,
				bankComment,
				transactions,
			};
		},

		getTransactionDate(transaction) {
			return transaction[0].split(' ')[0];
		},

		getTransactionTerminalId(transaction) {
			return transaction[0].split(' ')[1];
		},

		getTransactionTotal(transaction) {
			const transactions = transaction[1].transactions;

			let total = 0;

			for (const transaction of transactions) {
				total += +transaction.purchaseAmount;
			}

			return total;
		},

		async sendInvoice(preventEconomicRequest = false) {
			if (this.$v.form.$invalid || this.pdfOverlay.loading) {
				return false;
			}

			this.pdfOverlay.loading = true;

			const transactionIds = this.agreement[1].map((transaction) => transaction.id);
			const firstTransaction = this.agreement[1][0];

			let invoiceNumber = null;

			// Create the invoice and get the invoice number
			var formData = new FormData();
			formData.append('transactionIds', transactionIds);
			formData.append('customerId', firstTransaction.customer_id);
			formData.append('economicProduct', JSON.stringify(this.form.product));
			formData.append('quantity', this.form.productQuantity);
			formData.append('discountPercentage', this.form.productDiscount);

			// Optionally prevent economic
			if (preventEconomicRequest) {
				formData.append('preventEconomicRequest', preventEconomicRequest);
			}

			// Send the invoice
			invoiceNumber = await axios('transactions/send-invoice', {
				method: 'post',
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(function (response) {
					const invoiceNumber = response.data;

					return invoiceNumber;
				})
				.catch(function (error) {
					console.log(error);
				});

			if (!invoiceNumber) {
				return;
			}

			// Add invoice number to the PDF
			this.createPDFData(invoiceNumber);

			const blobUrl = createTransactionPDF(this.pdfOverlay.data);

			// Fetch the PDF
			const blob = await fetch(blobUrl).then((response) => response.blob());

			// Get UNIQUE Terminal Verifone Numbers for invoice
			const verifoneNumbers = new Set();
			for (const transaction of this.agreement[1]) {
				verifoneNumbers.add(transaction.terminal_verifoneNumber);
			}

			// Reset the form data from the last request
			formData = new FormData();
			formData.append('transactionIds', transactionIds);
			formData.append('customerId', firstTransaction.customer_id);
			formData.append('economicBookedInvoiceNumber', invoiceNumber);
			formData.append('amount', this.totalPurchaseAmount);
			formData.append('cardCost', this.percentageCost);

			// Join the verifone numbers, separated by comma
			formData.append('terminalVerifoneNumbers', [...verifoneNumbers].join(', '));

			// Append the invoice PDF file
			formData.append('transactionSettlementFile', new File([blob], `uppgerd-${invoiceNumber}.pdf`, { type: 'application/pdf' }));

			// window.open(blobUrl);

			await axios('transactions/settlement-file', {
				method: 'post',
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(function (response) {
					const invoiceNumber = response.data;

					return invoiceNumber;
				})
				.catch(function (error) {
					console.log(error);
				})
				.finally(() => {
					this.pdfOverlay.loading = false;
					this.pdfOverlay.visible = false;
					this.pdfOverlay.data = null;

					window.location.reload();
				});
		},
	},
};
</script>

<style lang="scss" scoped>
input {
	padding: 0.5rem !important;
}
</style>
